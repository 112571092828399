<template>
    <v-card width="100%" height="100%" v-if="loaded">
        <StoresList
            :stores="filterItems"
            :scrollerHeight="scrollerHeight - marginTop"
            @selected="selectStore"
        ></StoresList>
    </v-card>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
export const StoresList = () => import("./StoresList");
import * as promoActions from "../../store/actions/storePromos";
import * as storeActions from "../../store/actions/store";

export default {
    props: ["id", "scrollerHeight"],

    components: {
        StoresList
    },

    data: () => ({
        // scrollerHeight: 0
    }),

    computed: {
        ...mapState({
            stores: state => state.storeModule.stores,
            searchString: state => state.filterModule.storeFilter.text,
            showall: state => state.filterModule.storeFilter.all,
            loading: state => state.storeModule.loading,
            loaded: state => state.storeModule.loaded,
            error: state => state.storeModule.error,
        }),

        marginTop() {
            return '0'
        },

        filterItems() {
            return this.stores.filter(store => {
                const checkGLNFilter = this.showall
                    ? true
                    : store.CodiceNazionaleCoopGLN.length !== 0;
                const checkText =
                    this.searchString.length === 0
                        ? true
                        : store.Insegna.toLowerCase().indexOf(
                              this.searchString
                          ) !== -1 ||
                          store.AliasCliente.toLowerCase().indexOf(
                              this.searchString
                          ) !== -1 ||
                          store.IndirizzoInsegna.toLowerCase().indexOf(
                              this.searchString
                          ) !== -1 ||
                          store.CAPInsegna.toLowerCase().indexOf(
                              this.searchString
                          ) !== -1 ||
                          store.ComuneInsegna.toLowerCase().indexOf(
                              this.searchString
                          ) !== -1 ||
                          store.SiglaProvinciaInsegna.toLowerCase().indexOf(
                              this.searchString
                          ) !== -1 ||
                          store.CodiceNazionaleCoopGLN.toLowerCase().indexOf(
                              this.searchString
                          ) !== -1;

                return checkGLNFilter && checkText;
            });
        }
    },

    methods: {
        ...mapActions({
            loadStores: storeActions.STORE_GET_STORES
        }),

        ...mapMutations({
            resetStorePromo: promoActions.STOREPROMOS_RESET
        }),

        selectStore(id) {
            // :to="`playlist/${pls.id}`"
            this.resetStorePromo();
            this.$router.push({
                name: "StorePromos",
                params: { storeId: id }
            }).catch(() => {});
        },

//         onResize() {
//             this.$nextTick(function() {
//                 const vw = Math.max(
//                     document.documentElement.clientWidth || 0,
//                     window.innerWidth || 0
//                 );
//                 const vh = Math.max(
//                     document.documentElement.clientHeight || 0,
//                     window.innerHeight || 0
//                 );
//                 this.scrollerHeight = vh - this.$vuetify.application.top
// /*                 console.log(vw, vh);
//                 console.log(this.$vuetify); */
//             });
//         }
    },

    mounted() {
        this.$nextTick(function() {
            this.loadStores(this.id)
        });
    }
};
</script>

<style></style>
